import React, {useEffect, useState} from 'react';
import background from "../../../Assets/Image/login-bg.jpg";
import axios from "axios";
import Constant from "../../../Constant";
import {Link, useHistory} from "react-router-dom";

const Register = () => {

    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handleRegister = () => {
        axios.post(Constant.BASE_URL + '/register', input).then(res => {
            localStorage.host_token = res.data.token
            localStorage.host_name = res.data.name
            localStorage.host_email = res.data.email
            setErrors([])
            history.push('/')
            window.location.reload()
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    const history = useHistory();
    useEffect(() => {
        if (localStorage.host_token !== undefined) {
            history.push('/')
            window.location.reload()
        }
    }, [])


    return (
        <div className={'login-container'}>
            <img src={background} alt={'Login Image'}/>
            <div className="login-card">
                <h4 className={'text-center text-white'}>Register as a Host</h4>
                <form>
                    <label className={'w-100 mt-3'}>
                        Full Name
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Full Name'}
                            type={'name'}
                            name={'name'}
                            value={input.name}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.name !== undefined ? errors.name[0] : null}</small></p>
                    </label>
                    <label className={'w-100'}>
                        Email
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Email'}
                            type={'email'}
                            name={'email'}
                            value={input.email}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                    </label>

                    <label className={'w-100'}>
                        Phone
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Phone'}
                            type={'number'}
                            name={'phone'}
                            value={input.phone}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.phone !== undefined ? errors.phone[0] : null}</small></p>
                    </label>

                    <label className={'w-100'}>
                        Password
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Password'}
                            type={'password'}
                            name={'password'}
                            value={input.password}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.password != undefined ? errors.password[0] : null}</small></p>
                    </label>

                    <label className={'w-100'}>
                        Confirm Password
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Confirm Password'}
                            type={'password'}
                            name={'password_confirmation'}
                            value={input.password_confirmation}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.password_confirmation != undefined ? errors.password_confirmation[0] : null}</small></p>
                    </label>

                    <div className="d-grid mt-2">
                        <button onClick={handleRegister} type={'button'} className={'btn btn-outline-info btn-sm'}>Register</button>
                    </div>

                </form>
                <p className={'text-white mt-2'}>Already Registered? <Link to={'/login'} className={'text-warning'}>Login Now</Link></p>

            </div>
        </div>
    );
};

export default Register;

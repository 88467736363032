import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../Includes/BreadCrumb";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Constant from "../../Constant";
import GlobalFunctions from "../../GobalFunctions";
import $ from 'jquery'

const ListingCreate = () => {

    const history = useHistory()
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [amenities, setAmenities] = useState([])
    const [guest_favorite, set_guest_favorite] = useState([])
    const [safety_items, set_safety_items] = useState([])
    const [properties, setProperties] = useState([])
    const [properties_type, setProperties_type] = useState([])
    const [listing_type, setListing_type] = useState([])
    const [categories, setCategories] = useState([])
    const [countries, setCountries] = useState([])
    const [amenity_id, setAmenity_id] = useState([])
    const [guest_fvrt_id, setGuest_fvrt_id] = useState([])
    const [safety_id, setSafety_id] = useState([])

    const getCountries = () => {
        axios.get(`${Constant.BASE_URL}/get-countries`,).then(res => {
            setCountries(res.data)
        })
    }
    const getCategories= () => {
        axios.get(`${Constant.BASE_URL}/get-categories`,).then(res => {
            setCategories(res.data.data)
        })
    }
    const getListingType = () => {
        axios.get(`${Constant.BASE_URL}/get-listing-type`,).then(res => {
            setListing_type(res.data.data)
        })
    }
    const getPropertyType = (property_id) => {
        axios.get(`${Constant.BASE_URL}/get-property-type/${property_id}`,).then(res => {
            setProperties_type(res.data.data)
        })
    }
    const getProperty = () => {
        axios.get(`${Constant.BASE_URL}/get-property`, input).then(res => {
            setProperties(res.data.data)
        })
    }
    const getAmenities = () => {
        axios.get(`${Constant.BASE_URL}/get-amenities`, input).then(res => {
            setAmenities(res.data.data)
        })
    }
    const getGuestFavorite = () => {
        axios.get(`${Constant.BASE_URL}/guest-favourite`, input).then(res => {
            set_guest_favorite(res.data.data)
        })
    }
    const getSafetyItems = () => {
        axios.get(`${Constant.BASE_URL}/safety-items`, input).then(res => {
            set_safety_items(res.data.data)
        })
    }
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'title') {
            let value = GlobalFunctions.slugFormatter(e.target.value)
            setInput(prevState => ({...prevState, slug: value}))
        }
        if (e.target.name == 'property_id'){
            getPropertyType(e.target.value)
        }
    }
    const increaseGuestInput = (e, name) => {
        setInput(prevState => ({...prevState, [name] : input[name]+1}))
    }
    const decreaseGuestInput = (e, name) => {
        setInput(prevState => ({...prevState, [name] : input[name]-1}))
    }
    const handleSelect = (id, name) => {
        if (name == 'amenity'){
            let has_class =  $(`#amenity_item_${id}`).hasClass('active')
            if (has_class){
                $(`#amenity_item_${id}`).removeClass('active')
                let arr = amenity_id.filter((item) => item !== id);
                setAmenity_id(arr);
            }else{
                $(`#amenity_item_${id}`).addClass('active')
                if (!amenity_id.includes(id)) {
                    setAmenity_id(prevState => [...prevState, id])
                }
            }
        }else if(name == 'guest'){
            let has_class =  $(`#guest_item_${id}`).hasClass('active')
            if (has_class){
                $(`#guest_item_${id}`).removeClass('active')
                let arr = guest_fvrt_id.filter((item) => item !== id);
                setGuest_fvrt_id(arr);
            }else{
                $(`#guest_item_${id}`).addClass('active')
                if (!guest_fvrt_id.includes(id)) {
                    setGuest_fvrt_id(prevState => [...prevState, id])
                }
            }
        }else if(name == 'safety'){
            let has_class =  $(`#safety_item_${id}`).hasClass('active')
            if (has_class){
                $(`#safety_item_${id}`).removeClass('active')
                let arr = safety_id.filter((item) => item !== id);
                setSafety_id(arr);
            }else{
                $(`#safety_item_${id}`).addClass('active')
                if (!safety_id.includes(id)) {
                    setSafety_id(prevState => [...prevState, id])
                }
            }
        }
    }
    useEffect(() => {
        setInput(prevState => ({...prevState, amenity_id: amenity_id}))
    }, [amenity_id]);
    useEffect(() => {
        setInput(prevState => ({...prevState, guest_fvrt_id: guest_fvrt_id}))
    }, [guest_fvrt_id]);
    useEffect(() => {
        setInput(prevState => ({...prevState, safety_id: safety_id}))
    }, [safety_id]);

    const handleCategoryStore = () => {
        setIsLoading(true)
        setErrors([])
        axios.post(`${Constant.BASE_URL}/listing`, input).then(res => {
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 3000
            })
            localStorage.listing_id = res.data.listing_id
            history.push('/listing/add-photo')
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    useEffect(() => {
        getAmenities()
        getGuestFavorite()
        getSafetyItems()
        getProperty()
        getListingType()
        getCountries()
        getCategories()

        setInput({
            guest: 1,
            bed: 2,
            bathroom: 3,
            bedroom: 7,
        })
    }, []);

    useEffect(()=>(
        console.log(input)
    ),[input])

    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Add New Listing | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'Add New Listing'}/>
            <div className="row">
                <div className="col-md-12 ">
                    <div className="card  pb-2 mb-5">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Add New Listing</h4>
                            <Link to={'/listing'}>
                                <button className={'link-button-card'}><i className="fas fa-list"/></button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <label>
                                Listing Title
                                <input
                                    className={errors.name != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter Listing Title'}
                                    type={'text'}
                                    name={'title'}
                                    value={input.title}
                                    onChange={handleInput}
                                />
                                {errors.title != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.title[0]}</small></p> : null}
                            </label>
                            <label>
                                Listing Slug
                                <input
                                    className={errors.name != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter Listing Slug'}
                                    type={'text'}
                                    name={'slug'}
                                    value={input.slug}
                                    onChange={handleInput}
                                />
                                {errors.slug != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.slug[0]}</small></p> : null}
                            </label>
                            <label>
                                Listing Description
                                <textarea
                                    className={errors.description != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                    placeholder={'Enter  Listing Description'}
                                    name={'description'}
                                    value={input.description}
                                    onChange={handleInput}
                                    rows={7}
                                />
                                {errors.description != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.description[0]}</small></p> : null}
                            </label>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>
                                        Select Category
                                        <select
                                            className={errors.category_id != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                            placeholder={'Select Category'}
                                            name={'category_id'}
                                            value={input.category_id}
                                            onChange={handleInput}
                                        >
                                            <option>Select Category</option>
                                            {categories.map((property, index)=>(
                                                <option key={index} value={property.id}>{property.name}</option>
                                            ))}
                                        </select>
                                        {errors.category_id != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.category_id[0]}</small></p> : null}
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label>
                                        Select Property
                                        <select
                                            className={errors.name != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                            placeholder={'Select Property'}
                                            name={'property_id'}
                                            value={input.property_id}
                                            onChange={handleInput}
                                        >
                                            <option>Select Property</option>
                                            {properties.map((property, index)=>(
                                                <option key={index} value={property.id}>{property.name}</option>
                                            ))}
                                        </select>
                                        {errors.property_id != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.property_id[0]}</small></p> : null}
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label>
                                        Select Property Type
                                        <select
                                            className={errors.name != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                            placeholder={'Select Property Type'}
                                            name={'property_type_id'}
                                            value={input.property_type_id}
                                            onChange={handleInput}
                                        >
                                            <option>Select Property Type</option>
                                            {properties_type.map((property, index)=>(
                                                <option key={index} value={property.id}>{property.name}</option>
                                            ))}
                                        </select>
                                        {errors.property_type_id != undefined ?
                                            <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.property_type_id[0]}</small></p> : null}
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <label>
                                        Select Listing Type
                                        <select
                                            className={errors.name != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                            placeholder={'Select Listing Type'}
                                            name={'listing_type_id'}
                                            value={input.listing_type_id}
                                            onChange={handleInput}
                                        >
                                            <option>Select Listing Type</option>
                                            {listing_type.map((property, index)=>(
                                                <option key={index} value={property.id}>{property.name}</option>
                                            ))}
                                        </select>
                                        {errors.listing_type_id != undefined ?
                                            <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.listing_type_id[0]}</small></p> : null}
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card h-100">
                                        <div className="card-header">
                                            <h5>Address Section</h5>
                                        </div>
                                        <div className="card-body">
                                            <label>
                                                Street
                                                <input
                                                    className={errors.street != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                    placeholder={'Enter Listing Street'}
                                                    type={'text'}
                                                    name={'street'}
                                                    value={input.street}
                                                    onChange={handleInput}
                                                />
                                                {errors.street != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.street[0]}</small></p> : null}
                                            </label>
                                            <label>
                                                Apt, suite, etc. (Optional)
                                                <input
                                                    className={errors.apartment != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                    placeholder={'Enter Apt, suite, etc.'}
                                                    type={'text'}
                                                    name={'apartment'}
                                                    value={input.apartment}
                                                    onChange={handleInput}
                                                />
                                                {errors.apartment != undefined ?
                                                    <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.apartment[0]}</small></p> : null}
                                            </label>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        City
                                                        <input
                                                            className={errors.city != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                            placeholder={'Enter City'}
                                                            type={'text'}
                                                            name={'city'}
                                                            value={input.city}
                                                            onChange={handleInput}
                                                        />
                                                        {errors.city != undefined ?
                                                            <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.city[0]}</small></p> : null}
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        State
                                                        <input
                                                            className={errors.state != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                            placeholder={'Enter State'}
                                                            type={'text'}
                                                            name={'state'}
                                                            value={input.state}
                                                            onChange={handleInput}
                                                        />
                                                        {errors.state != undefined ?
                                                            <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.state[0]}</small></p> : null}
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Zip Code
                                                        <input
                                                            className={errors.zip != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                            placeholder={'Enter ZIP Code'}
                                                            type={'text'}
                                                            name={'zip'}
                                                            value={input.zip}
                                                            onChange={handleInput}
                                                        />
                                                        {errors.zip != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.zip[0]}</small></p> : null}
                                                    </label>
                                                </div>
                                            </div>
                                            <label>
                                                Select Country
                                                <select
                                                    className={errors.country_id != undefined ? 'form-select form-select-sm is-invalid' : 'form-select form-select-sm'}
                                                    placeholder={'Select Country'}
                                                    name={'country_id'}
                                                    value={input.country_id}
                                                    onChange={handleInput}
                                                >
                                                    <option>Select Country</option>
                                                    {countries.map((country, index)=>(
                                                        <option key={index} value={country.id}>{country.name}</option>
                                                    ))}
                                                </select>
                                                {errors.country_id != undefined ?
                                                    <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.country_id[0]}</small></p> : null}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card h-100">
                                        <div className="card-header">
                                            <h5>Listing Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h6>Guest/Floor Details</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h6>Guest</h6>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button  disabled={input.guest < 2 ? true:false} onClick={(e)=>decreaseGuestInput(e,'guest')} className={'increase-decrease-button'}><i className={'fas fa-minus'}/></button>
                                                                <input
                                                                    className={errors.guest != undefined ? 'form-control guest-input form-control-sm is-invalid' : 'form-control guest-input form-control-sm'}
                                                                    placeholder={'Enter Listing Street'}
                                                                    type={'number'}
                                                                    name={'guest'}
                                                                    value={input.guest}
                                                                    readOnly={true}
                                                                />
                                                                <button onClick={(e)=>increaseGuestInput(e,'guest')} className={'increase-decrease-button'}><i className={'fas fa-plus'}/></button>
                                                            </div>
                                                            {errors.guest != undefined ?
                                                                <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.guest[0]}</small></p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <h6>Beds</h6>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button  disabled={input.bed < 1 ? true:false} onClick={(e)=>decreaseGuestInput(e,'bed')} className={'increase-decrease-button'}><i className={'fas fa-minus'}/></button>
                                                                <input
                                                                    className={errors.beds != undefined ? 'form-control guest-input form-control-sm is-invalid' : 'form-control guest-input form-control-sm'}
                                                                    placeholder={'Enter Listing Street'}
                                                                    type={'number'}
                                                                    name={'bed'}
                                                                    value={input.bed}
                                                                />
                                                                <button onClick={(e)=>increaseGuestInput(e,'bed')} className={'increase-decrease-button'}><i className={'fas fa-plus'}/></button>
                                                            </div>
                                                            {errors.beds != undefined ?
                                                                <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.beds[0]}</small></p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <h6>Bedrooms</h6>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button disabled={input.bedroom < 1 ? true:false} onClick={(e)=>decreaseGuestInput(e,'bedroom')} className={'increase-decrease-button'}><i className={'fas fa-minus'}/></button>
                                                                <input
                                                                    className={errors.bedrooms != undefined ? 'form-control guest-input form-control-sm is-invalid' : 'form-control guest-input form-control-sm'}
                                                                    placeholder={'Enter Listing Street'}
                                                                    type={'number'}
                                                                    name={'bedroom'}
                                                                    value={input.bedroom}
                                                                />
                                                                <button onClick={(e)=>increaseGuestInput(e,'bedroom')} className={'increase-decrease-button'}><i className={'fas fa-plus'}/></button>
                                                            </div>
                                                            {errors.bedrooms != undefined ?
                                                                <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.bedrooms[0]}</small></p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <h6>Bathrooms</h6>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button disabled={input.bathroom < 1 ? true:false} onClick={(e)=>decreaseGuestInput(e,'bathroom')} className={'increase-decrease-button'}><i className={'fas fa-minus'}/></button>
                                                                <input
                                                                    className={errors.bathrooms != undefined ? 'form-control guest-input form-control-sm is-invalid' : 'form-control guest-input form-control-sm'}
                                                                    placeholder={'Enter Listing Street'}
                                                                    type={'number'}
                                                                    name={'bathroom'}
                                                                    value={input.bathroom}
                                                                />
                                                                <button  onClick={(e)=>increaseGuestInput(e,'bathroom')} className={'increase-decrease-button'}><i className={'fas fa-plus'}/></button>
                                                            </div>
                                                            {errors.bathrooms != undefined ?
                                                                <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.bathrooms[0]}</small></p> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Select Amenities</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="amenities-list">
                                                {amenities.map((amenity, index) => (
                                                    <div onClick={(e)=>handleSelect(amenity.id, 'amenity')} key={index} id={`amenity_item_${amenity.id}`} className={'amenities-item'}>
                                                        <img src={amenity.icon} alt={amenity.name}/>
                                                        <p>{amenity.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Select Guest Favourite</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="amenities-list">
                                                {guest_favorite.map((amenity, index) => (
                                                    <div key={index} onClick={(e)=>handleSelect(amenity.id, 'guest')} key={index} id={`guest_item_${amenity.id}`} className={'amenities-item'}>
                                                        <img src={amenity.icon} alt={amenity.name}/>
                                                        <p>{amenity.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Select Safety Items</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="amenities-list">
                                                {safety_items.map((amenity, index) => (
                                                    <div key={index} onClick={(e)=>handleSelect(amenity.id, 'safety')} key={index} id={`safety_item_${amenity.id}`} className={'amenities-item '}>
                                                        <img src={amenity.icon} alt={amenity.name}/>
                                                        <p>{amenity.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Price Section</h5>
                                        </div>
                                        <div className="card-body">
                                            <label>
                                                Price (per night)
                                                <div className="input-group">
                                                    <button style={{width: '50px'}} className={'input-group-text btn btn-info btn-sm'}>$</button>
                                                    <input
                                                        className={errors.price != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                        placeholder={'Enter Listing Price'}
                                                        type={'number'}
                                                        name={'price'}
                                                        value={input.price}
                                                        onChange={handleInput}
                                                    />
                                                </div>
                                                {errors.price != undefined ? <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.price[0]}</small></p> : null}
                                            </label>
                                            <label>
                                                Discount (%)
                                                <div className="input-group">
                                                    <button style={{width: '50px'}} className={'input-group-text btn btn-info btn-sm'}>%</button>
                                                    <input
                                                        className={errors.discount != undefined ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                                                        placeholder={'Enter Discount Percent'}
                                                        type={'number'}
                                                        name={'discount'}
                                                        value={input.discount}
                                                        onChange={handleInput}
                                                    />
                                                </div>
                                                {errors.discount != undefined ?
                                                    <p className={'error-message'}><small><i className="fas fa-exclamation-triangle"/>{errors.discount[0]}</small></p> : null}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer text-end">
                            <button onClick={handleCategoryStore} className={'btn btn-success'}>Next</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ListingCreate;

import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../Includes/BreadCrumb";
import loader from "../../Assets/Image/loader/loader.svg";
import {Link} from "react-router-dom";
import axios from "axios";
import Constant from "../../Constant";
import Swal from "sweetalert2";

const MyListing = () => {


    const [listings, setListings] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [startFrom, setStartFrom] = useState(1)
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const getListing = (pageNumber, type = 'regular') => {
        setActivePage(pageNumber)
        axios.get(`${Constant.BASE_URL}/listing?page=${pageNumber}&search=${search}`).then(res => {
            setListings(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setStartFrom(res.data.meta.from)
            setIsLoading(false)
            setIsSearchLoading(false)
        })
    }

    const handleCategoryDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Category Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/category/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListing()
                })
            }
        })
    }

    const handleSearchInput = (e) => {
        setSearch(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handleSearch = () => {
        getListing(1, 'search')
    }
    useEffect(() => {
        getListing()
    }, [])


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>My Listing Listing | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'My Listing List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>My Listing</h4>
                            <Link to={'/listing/create'}>
                                <button className={'link-button-card'}><i className="fas fa-plus"/></button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-end">
                                <div className="col-md-4">
                                    <div className="d-flex align-items-center">
                                        <label>
                                            Search Listing
                                            <span className="input-group">
                                            <input
                                                type={'search'}
                                                name={'search'}
                                                className={'form-control form-control-sm'}
                                                value={search}
                                                onChange={handleSearchInput}
                                            />
                                            <button onClick={handleSearch} className="input-group-text btn-sm search-button"
                                                    dangerouslySetInnerHTML={{__html: isSearchLoading ? '<div class="spinner-border spinner-border-sm"></div>' : '<i class="fas fa-search"></i> <span class="ms-2">Search</span>'}}/>
                                        </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <table className={'table table-sm table-bordered table-hover table-striped'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Photo</th>
                                    <th>Category</th>
                                    <th>Date Time</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {listings.map((listing, index)=>(
                                    <tr>
                                        <td>{++index}</td>
                                        <td>{listing.title}</td>
                                        <td>
                                            <small>
                                                <p>Original Price : {listing.original_price}</p>
                                                <p>Discount : {listing.discount}</p>
                                                <p>Amount : {listing.discount_price}</p>
                                            </small>
                                        </td>
                                        <td>
                                            <img src={listing.cover_photo} alt={"Photo"} style={{width: "75px"}} className={"img-thumbnail"} />
                                        </td>
                                        <td>{listing.category}</td>
                                        <td>{listing.created_at}</td>
                                        <td>
                                            <Link to={"#"}><button className={"btn btn-sm btn-info"}><i className="fas fa-eye"></i> </button></Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            {/*<div className={'text-center'}><p className={'text-danger'}>No Data Found</p></div>*/}


                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyListing;

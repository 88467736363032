import React, {useEffect, useState} from 'react';
import background from './../../../Assets/Image/login-bg.jpg'
import axios from "axios";
import Constant from "../../../Constant";
import {Link, useHistory} from "react-router-dom";
import GoogleLogin from 'react-google-login';

const Login = () => {
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handleLogin = () => {
        axios.post(Constant.BASE_URL + '/login', input).then(res => {
            localStorage.host_token = res.data.token
            localStorage.host_name = res.data.name
            localStorage.host_email = res.data.email
            setErrors([])

            history.push('/')
            window.location.reload()
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    const history = useHistory();
    useEffect(() => {
        if (localStorage.host_token !== undefined) {
            history.push('/')
            window.location.reload()
        }
    }, [])


    const responseGoogle = (res) =>{
        console.log('success')
        console.log(res)
    }

    const handleFail = (res) =>{
        console.log('fail')
        console.log(res)
    }

    return (
        <div className={'login-container'}>
            <img src={background} alt={'Login Image'}/>
            <div className="login-card">
                <h4 className={'text-center'}>Host Login</h4>
                <form>
                    <label className={'w-100 mt-1'}>
                        Email/Phone
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Name'}
                            type={'text'}
                            name={'email'}
                            value={input.email}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                    </label>
                    <label className={'w-100 mt-3'}>
                        Password
                        <input
                            className={'form-control form-control-sm'}
                            placeholder={'Enter Name'}
                            type={'password'}
                            name={'password'}
                            value={input.password}
                            onChange={handleInput}
                        />
                        <p className={'login-error-message'}><small className={'text-danger'}>{errors.password != undefined ? errors.password[0] : null}</small></p>

                    </label>
                    <div className="d-grid mt-4">
                        <button onClick={handleLogin} type={'button'} className={'btn btn-outline-info btn-sm'}>Login</button>
                    </div>
                </form>
           <p className={'text-white mt-2'}>Not Registered yet? <Link to={'/register'} className={'text-warning'}>Register Now</Link></p>
            </div>
        </div>
    );
};

export default Login;

import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../Includes/BreadCrumb";
import {Link, useHistory} from "react-router-dom";
import $ from 'jquery'
import default_icon from './../../Assets/Image/login-bg.jpg'
import axios from "axios";
import Constant from "../../Constant";

const AddListingPhoto = () => {
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [icon, setIcon] = useState(default_icon)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const [listing_id, set_listing_id] = useState(null)
    const [photos, setPhotos] = useState([])
    const [uploadedPhotos, setUploadedPhotos] = useState([])
    const [progress, setProgress] = useState(0);
    const triggerImage = () => {
        $('#image_upload_input').trigger('click')
    }
    const handleImageUpload = (e) => {
        let files = e.target.files
        for (let i = 0 ; i < files.length; i++){
            let reader = new FileReader()
            reader.onloadend = () => {
                let input = {}
                input.listing_id = localStorage.listing_id
                input.photo = reader.result
                if (i == 0){
                    input.primary = 1
                }else {
                    input.primary = 0
                }
                setPhotos(prevState => ([...prevState, input]))
            }
            reader.readAsDataURL(files[i])
        }
    }
    const uploadPhoto = () => {
        axios.post(`${Constant.BASE_URL}/add-photo`, {photos: photos}, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progress);
            }}).then(res => {
            setUploadedPhotos(res.data.data)
            setPhotos([])
        })
    }
    useEffect(() => {
        if (Object.keys(photos).length > 4){
            uploadPhoto()
        }
    }, [photos]);


    useEffect(()=>{
        if (localStorage.listing_id != undefined && localStorage.listing_id != null){
            set_listing_id(localStorage.listing_id)
        }else{
            history.push('/listing')
        }
    }, [])

    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Add Photo to Listing | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'Add Photo to Listing'}/>
            <div className="row">
                <div className="col-md-12 ">
                    <div className="card">
                        <div className="card-header">
                            <h4>Add Photo To Listing</h4>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <input
                                        className={'d-none'}
                                        type={'file'}
                                        name={'photos'}
                                        multiple={true}
                                        id={'image_upload_input'}
                                        onChange={handleImageUpload}
                                    />
                                    <div className="category-icon-container">
                                        <i onClick={triggerImage} className="fas fa-camera"></i>
                                        <img src={icon} className={'img-thumbnail'} alt={'image'}/>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row mt-4">
                                {uploadedPhotos.map((photo, index)=>(
                                    <div className="col-md-2 my-2" key={photo.id}>
                                        <img src={photo.photo}  alt={'Photos'} className={'img-thumbnail'} />
                                    </div>
                                ))}

                                <div className="row justify-content-center mt-3">
                                    <div className="col-md-6">
                                        <div className="progress" style={{display: `${progress < 1 ? 'none': 'block'}`}}>
                                            <div className="progress-bar progress-bar-striped bg-info" role="progressbar"
                                                 style={{width: `${progress}%`}} aria-valuenow="50" aria-valuemin="0"
                                                 aria-valuemax="100">{progress}%</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 text-end my-4">
                                    <Link to={'/listing'}><button className={'btn w-25 btn-outline-success'}>Done</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddListingPhoto;

import React from 'react';
import BreadCrumb from "../Includes/BreadCrumb";
import {Helmet} from "react-helmet";

const Home = () => {
    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Host Panel | Dayfuna</title>
            </Helmet>
            <BreadCrumb title={'Host Panel'}/>
            <div className="row">
                <div className="col-xl-3 col-md-6">
                    <div className="card bg-primary text-white mb-4 text-center">
                        <div className="card-header">
                            <h4> Total Order</h4>
                        </div>
                        <div className="card-body">
                           <h1>05</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <a className="small text-white stretched-link" href="#">View Details</a>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card bg-warning text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total User</h4>
                        </div>
                        <div className="card-body">
                           <h1>50</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <a className="small text-white stretched-link" href="#">View Details</a>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card bg-info text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total Service</h4>
                        </div>
                        <div className="card-body">
                           <h1>500</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <a className="small text-white stretched-link" href="#">View Details</a>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card bg-danger text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total Amount</h4>
                        </div>
                        <div className="card-body">
                           <h1>5000</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <a className="small text-white stretched-link" href="#">View Details</a>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Home;

import React from 'react';

const Footer = () => {
    return (
        <footer className="py-1 mt-auto theme-bg">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">Copyright &copy; Dayfuna {new Date().getFullYear()} | <small>Version: Beta 0.0.1</small></div>
                    <div className={'footer'}>
                        <a href="#">Privacy Policy</a>
                       |
                        <a href="#">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

const GlobalFunctions =  {
    slugFormatter(slug) {
        slug = slug.replaceAll('&', ' and ')
        slug = slug.replaceAll(' & ', ' and ')
        slug = slug.replaceAll(' ', '-')
        slug = slug.replaceAll('--', '-')
        slug = slug.replaceAll(' / ', '-')
        slug = slug.replaceAll('-/-', '-')
        slug = slug.replaceAll('/', '-')
        slug = slug.replaceAll(',', '-')
        slug = slug.replaceAll(' , ', '-')
        slug = slug.replaceAll(', ', '-')
        slug = slug.replaceAll(';', '-')
        slug = slug.replace(/[^a-zA-Z0-9]+/g, '-')
        slug = slug.toLowerCase()
        return slug
    }
}
export default GlobalFunctions

import './Assets/Js/scripts'
import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import './Assets/Style/sb_styles.css'
import './Assets/Style/style.scss';
import './App.css';
import Home from "./Components/Pages/Home";
import Nav from "./Components/Includes/Nav";
import SideBar from "./Components/Includes/SideBar";
import Footer from "./Components/Includes/Footer";
import axios from "axios";
import {useEffect, useState} from "react";
import Constant from "./Constant";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./Components/Pages/Auth/Login";
import Auth from "./Auth";
import Register from "./Components/Pages/Auth/Register";
import ListingCreate from "./Components/Pages/ListingCreate";
import OrderList from "./Components/Pages/Order/OrderList";
import MyListing from "./Components/Pages/MyListing";
import AddListingPhoto from "./Components/Pages/AddListingPhoto";


function App() {


  const [url, setUrl] = useState('')
  useEffect(() => {
    setUrl(window.location.pathname)
    checkAuth()
    setTimeout(()=>{
      checkAuth()
    }, 900000)
  }, [])

  const [auth, setAuth] = useState(false);
  const checkAuth = () => {
    let url = window.location.pathname
    const token = localStorage.host_token
    const urls = ['/login', '/register']
    if (!urls.includes(url)) {
      axios.post(Constant.BASE_URL + '/auth-check').then(res => {
        if (res.data.msg === true) {
          setAuth(res.data.msg)
        } else {
          localStorage.removeItem('host_token')
          localStorage.removeItem('host_name')
          localStorage.removeItem('host_email')
          window.location.reload()
        }
      }).catch(errors=>{
        // if (errors.response.status === 401){
        localStorage.removeItem('host_token')
        localStorage.removeItem('host_name')
        localStorage.removeItem('host_email')
        window.location.reload()
        // }
      })
    } else {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }
  }


  return (
      <>
        {url === '/login' || url === '/register' ?
            <BrowserRouter>
              <Route exact path={'/login'} component={Login}/>
              <Route exact path={'/register'} component={Register}/>
            </BrowserRouter> :
            <div className="App">
              <BrowserRouter>
                <div className="sb-nav-fixed text-start">
                  <Nav/>
                  <div id="layoutSidenav">
                    <SideBar/>
                    <div id="layoutSidenav_content">
                      <main>
                        <Auth exact path={'/demo-route'} component={Home}/>
                        <Auth exact path={'/'} component={Home}/>
                        <Auth exact path={'/listing'} component={MyListing}/>
                        <Auth  path={'/listing/create'} component={ListingCreate}/>
                        <Auth  path={'/listing/add-photo'} component={AddListingPhoto}/>
                        <Auth  exact path={'/order'} component={OrderList}/>
                        <Auth  exact path={'/transaction'} component={OrderList}/>
                        <Auth  exact path={'/report'} component={OrderList}/>
                      </main>
                      <Footer/>
                    </div>
                  </div>
                </div>
              </BrowserRouter>
            </div>
        }
      </>
  );
}

export default App;
